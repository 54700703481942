@import 'variables.scss';
@import 'table.scss';
@import 'accessibility.scss';

body,
#root {
  font-size: $font-size;
  font-family: $font-family;
}

.app-footer.ant-layout-footer {
  -webkit-box-shadow: 0px -3px 20px -1px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px -3px 20px -1px rgba(0,0,0,0.2);
  box-shadow: 0px -3px 20px -1px rgba(0,0,0,0.2);

  order: 99; // should be last item
  background-color: $filter-main;
  text-align: right;

  border-radius: 50;
  vertical-align: top;

  a.bmaw-link {
    color: white !important;

    &:hover {
      color: darken(white, 7) !important;
    }
  }
}

.ant-layout-content {
  padding: $page-padding-mobile;

  @media screen and (min-width: $lg) {
    padding: $page-padding;
  }
}

.ant-card-body {
  color: $font-color;
}

a {
  color: $blue-dark !important;

  &:hover {
    color: $blue-medium !important;
  }
}

.underlined {
  text-decoration: underline !important;
}

h1,
h2,
h3 {
  color: $blue-dark !important;
}

h1 {
  font-size: $font-size-lg;
}

h2 {
  font-size: calc(#{$font-size-lg} - 4px);
}

.btn-with-icon {
  i,
  svg {
    margin-left: 10px;
  }
}

.btn-with-icon-right {
  i,
  svg {
    margin-right: 10px;
  }
}

@mixin button-color($color) {
  background: $color;
  border-color: $color;

  &:hover {
    background: darken($color, 5);
  }
}

@mixin button-secondary-color($color) {
  color: $color;
  border-color: $color;

  &:hover {
    border-color: lighten($color, 5);
    color: lighten($color, 5);
    background: darken(white, 1);
  }
}

.ant-btn.ant-btn-primary {
  height: auto;
  border-radius: 3px;
  font-weight: 600;

  &.yellow {
    @include button-color($yellow);
  }
  &.orange {
    @include button-color($orange);
  }
  &.blue-light {
    @include button-color($blue-light);
  }
  &.blue-medium {
    @include button-color($blue-medium);
  }
  &.blue-dark {
    @include button-color($blue-dark);
  }
  &.teal {
    @include button-color($teal);
  }
}
.ant-btn.ant-btn-secondary {
  &.blue-medium {
    @include button-secondary-color($blue-medium);
  }
  &.yellow {
    @include button-secondary-color($yellow);
  }
  &.red {
    @include button-secondary-color($red);
  }
  &.orange {
    @include button-secondary-color($orange);
  }
}

.switch {
  &.yellow {
    &.ant-switch-checked {
      background-color: $yellow;
    }
  }
}

.ant-btn.icon-btn {
  border: none;
  background: transparent;
  border-radius: 50%;

  &:hover,
  &:focus,
  &:active {
    color: $blue-dark;
    background: transparent;
  }

  &:disabled,
  &:disabled:hover {
    background: transparent;
  }

  &.round {
    margin: 0 10px;
    border: 2px solid #fff;
    color: #fff;
    padding: 0;

    $size: 30px;
    min-height: $size;
    min-width: $size;
    max-height: $size;
    max-width: $size;
    height: $size;
    width: $size;
    box-shadow: -3px 3px 8px rgba(#000, 0.1);
  }
}

.ant-btn.link-style-btn {
  border: none;
  background: transparent;
  color: $yellow;
  box-shadow: none;
  padding: 0;

  &:hover,
  &:active {
    color: $orange;
    background: transparent;
  }

  &:disabled,
  &:disabled:hover {
    background: transparent;
  }
}

.ant-btn.table-action-btn {
  border-radius: 50%;
  color: #fff;
  height: 30px;
  width: 30px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;

  &.yellow {
    background: $yellow;
    &:hover {
      background: darken($yellow, 5);
    }
  }
  &.orange {
    background: $orange;
    &:hover {
      background: darken($orange, 5);
    }
  }
  &.blue-light {
    background: $blue-light;
    &:hover {
      background: darken($blue-light, 5);
    }
  }
  &.blue-dark {
    background: $blue-dark;
    &:hover {
      background: darken($blue-dark, 5);
    }
  }
  &.teal {
    background: $teal;
    &:hover {
      background: darken($teal, 5);
    }
  }
  &.red {
    background: $red;
    &:hover {
      background: darken($red, 5);
    }
  }

  &:hover,
  &:focus,
  &:active {
    color: #fff;
  }
}

.app-icon {
  $size: 60px;
  min-height: $size;
  min-width: $size;

  max-height: $size;
  max-width: $size;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  line-height: 1;

  i,
  svg,
  img {
    max-height: 40px;
    max-width: 40px;

    width: 40px;
    color: #fff;
    fill: #fff;
  }
}

.blue {
  color: $blue-dark;
}

.green {
  color: $green;
}

.red {
  color: $red;
}

.table-actions {
  display: flex;
  justify-content: center;

  .ant-btn.table-action-btn {
    margin-right: 5px;
  }
}

.fontSize20 {
  font-size: 20px;
}

.limit-text {
  display: block;
  width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.center {
  margin: 0 auto;
}

.ant-popover-message {
  & > .anticon {
    color: #ff4d4f !important;
  }
}
.ant-popover-buttons {
  .ant-btn-dangerous.ant-btn-primary {
    padding: 4px 15px;
  }

  .ant-btn-sm {
    height: 32px;
  }
}

.ant-form-item-label > label {
  font-size: $font-size !important;
}

// Modal Styling
.ant-modal {
  .ant-modal-header {
    padding-left: 40px;
    padding-right: 40px;
    border-bottom-color: $blue-medium;
  }

  .ant-modal-close {
    right: 20px;
    color: $blue-medium;
  }

  .ant-modal-close-x {
    font-size: 28px;
  }

  .ant-modal-close:hover {
    color: lighten($blue-medium, 5);
  }

  .ant-modal-body {
    margin-top: 10px;
    padding-left: 40px;
    padding-right: 40px;
    margin-bottom: 10px;
  }

  .ant-modal-title {
    color: $blue-dark;
    font-size: 18px;
  }
}

.tox-tinymce-aux .tox-notification--warning {
  display: none !important;
}
