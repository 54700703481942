.ant-table {
  .ant-table-thead > tr > th {
    background: $blue-dark;
    color: #fff;
  }

  tr.ant-table-row:nth-child(2n) {
    background: $blue-gray-light;
  }

  .ant-table-cell::before {
    display: none;
  }

  .ant-table-thead th.ant-table-column-has-sorters:hover {
    background: $blue-dark;
  }

  .ant-table-column-title {
    font-weight: 600;
  }

  .ant-table-thead > tr > th {
    font-weight: 600;
  }

  td.ant-table-column-sort:not(.ant-table-row:hover) {
    background: none ;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: #fafafa !important;
  }
}

