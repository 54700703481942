@import '../../styles/variables';

.custom-required-mark {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
}

.custom-form-fontSize {
  font-size: 16px !important;
}

//type select cards start
.step-content {
  @media screen and (max-width: $md) {
    .select-card {
      margin-bottom: 2rem;
    }
  }
  .ant-card-bordered {
    background-color: $gray-light;
  }

  .type-card {
    height: 100%;

    .ant-card-head {
      background-color: $blue-medium;
      color: white;
      text-align: center;
      font-size: large;
    }

    .card-content {
      word-wrap: break-word;

      p {
        margin-top: 1em;
      }
    }
  }
}
//type select cards end

.table-tag {
  padding-bottom: 7px;
  display: inline-block;
}

.custom_uploadThumbnailButton {
  visibility: hidden;
}

.image-upload-error-text {
  color: red;
  align-self: center;
  justify-self: center;
}

.upload-cover-image {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: auto;
  width: auto;

  .thumbnail-cropper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .cropper-upload-btn {
      height: 4em;
      margin-top: 8px;
    }

    .image-crop {
      @media screen and (min-width: $xl) {
        max-width: 55% !important;
        max-height: auto;
      }
      @media screen and (min-width: $lg) and (max-width: $xxl) {
        max-width: 70% !important;
        max-height: auto;
      }
      @media screen and (max-width: $lg) {
        max-width: 100% !important;
        max-height: auto;
      }

      img {
        width: 100%;
        height: auto;
        object-fit: cover;
      }

      .edit-hover-container {
        text-align: center;
        position: relative;

        .overlay {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          width: 100%;
          background: rgba(0, 0, 0, 0.2);
          color: #fff;
          opacity: 0;

          .overlay-icon-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;

            .overlay-icon {
              align-self: center;
            }
          }
        }
        &:hover {
          .overlay {
            opacity: 1;
          }
          img {
            -webkit-filter: blur(2px);
            filter: blur(2px);
          }
        }
        &:focus-visible {
          .overlay {
            opacity: 1;
          }
          img {
            -webkit-filter: blur(2px);
            filter: blur(2px);
          }
        }
      }

      img,
      .edit-hover-container {
        transition: 0, 3 all;
      }
    }
  }

  .thumbnail-cropper-controls {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  & > * {
    margin-bottom: 1rem;
  }
}

.section-header {
  margin-bottom: 2rem;
}

.ant-modal-footer {
  padding: 1.5rem;
  border-top: 1px solid $blue-medium;
}

.ant-form-item-has-error {
  .tox-tinymce {
    border-color: #ff4d4f;
  }
}
.upload-cover-image {
  .ant-form-item-control {
    align-items: center;
  }
}

.buttonbar-container {
  display: inline-flex;
  width: 100%;
  .button-container {
    display: block;
    width: 112px;
    .buttonbar-button {
      width: 45%;
      margin: 1px;
    }
  }
}

.ant-modal {
  &.selectTypeModal {
    @media screen and (min-width: $xl) {
      width: 55% !important;
    }
    @media screen and (min-width: $lg) and (max-width: $xxl) {
      width: 70% !important;
    }
    @media screen and (max-width: $lg) {
      width: 100% !important;
    }

    .ant-modal-footer {
      .footer-btns {
        display: flex;
        justify-content: space-between;

        & > * {
          display: flex;
          @media screen and (max-width: $xl) {
            flex-direction: column;

            & > * {
              margin-left: 0px;
            }
            & > :first-child {
              margin-bottom: 1rem;
            }
          }
        }
        @media screen and (max-width: $sm) {
          flex-direction: column;
          & > :first-child {
            margin-bottom: 2rem;
          }
        }
      }
    }
  }
}

//add source section in form start
.add-row-btn {
  width: 62.5%;
  @media screen and (max-width: $lg) {
    width: 100%;
  }
}

.remove-row-btn {
  @media screen and (max-width: $xxxl) {
    float: right;
    margin-bottom: 2rem;
  }
}

.radio-btns-col {
  @media screen and (min-width: $lg) {
    text-align: center;
  }
}
.ref-number {
  @media screen and (min-width: $lg) and (max-width: $xl) {
    padding-left: 8px !important;
  }
}
//add source section in form end

//gallery component form start
.form-gallery-modal {
  margin-top: 2rem;
}

.btns-gallery-modal {
  display: flex;
  justify-content: space-between;
}

.table-tabswitch {
  .ant-tabs-ink-bar {
    background: $yellow;
  }

  .ant-tabs-content-holder {
    visibility: hidden !important;
  }
}
//gallery component form end

.custom-ant-table-column-title {
  width: 100%;
  height: 100%;

  text-align: left;
  background-color: inherit;
  outline: none;
  border-width: 0px;
  cursor: pointer;
  font-weight: 600;
}

.accessible-checkmark-row {
  width: 100%;
  height: 2em;

  :hover {
    background-color: $gray-light;
  }

  .accessible-checkmark {
    width: 100%;
    align-items: center;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-left: 0.5em;
    font-weight: 500;

    .ant-checkbox-checked .ant-checkbox-inner {
      border-color: $yellow;
      background: $yellow;
    }

    .ant-checkbox {
      top: 0em;
    }
  }
}

.active-sorting-icon {
  color: $yellow;
}

.inactive-sorting-icon {
  color: #bfbfbf;

  :hover {
    transition: all 0.3s;
    color: rgba(0, 0, 0, 0.45);
    background: rgba(0, 0, 0, 0.04);
  }
}

.tags-list-container {
  @media screen and (max-width: $xl) {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 5px;
    margin-top: 5px;
  }

  display: inline-block;

  .tag-spacing {
    margin-right: 0.5em !important;

    &.category {
      background: #fcf2db;
      border: 1.5px solid #994700;
      color: #994700;
    }

    &.cluster {
      background: $blue-light;
      border: 1.5px solid $filter-main;
      color: $filter-main;
    }
  }

  .tag-text {
    white-space: normal;
    margin-right: 0rem !important;
  }
}

.ant-table-content {
  overflow: auto scroll;
  min-height: 50vh;
}

.ant-table-content::-webkit-scrollbar {
  width: 15px;
}

.ant-table-content::-webkit-scrollbar-track {
  border-radius: 10px;
  border: 1px solid #edeff2;
}

.ant-table-content::-webkit-scrollbar-thumb {
  background: rgb(195, 195, 195);
  width: 10px;
  border-radius: 3px;
}

.ant-table-content::-webkit-scrollbar-thumb:hover {
  background: rgb(195, 195, 195);
}
