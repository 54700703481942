@import '../../styles/variables';

// TABCONTENT START
.ant-tabs>.ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-list {
  margin-left: 10px;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: $blue-dark;
  font-size: $font-size;
}

.ant-tabs-top>.ant-tabs-nav::before,
.ant-tabs-bottom>.ant-tabs-nav::before,
.ant-tabs-top>div>.ant-tabs-nav::before,
.ant-tabs-bottom>div>.ant-tabs-nav::before {
  border-bottom: none;
}

.ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-tab-active {
  border-bottom: none;
}

.ant-tabs-card>.ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card>div>.ant-tabs-nav .ant-tabs-tab {
  background: $blue-light;
  color: $blue-dark;
  font-weight: 600;
  border: none;
  font-size: $font-size;
}

.ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab+.ant-tabs-tab {
  margin-left: 20px;

  &:first-child {
    margin-left: 0;
  }
}

.ant-tabs-card>.ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card>div>.ant-tabs-nav .ant-tabs-tab-active {
  color: $blue-dark;
  background: $gray-middle;
}

.ant-tabs-content-holder {
  //background-color: #fafafa;
  background-color: $gray-light;
  padding: 15px 10px;
  border-radius: 6px;
}

.ant-tabs-top>.ant-tabs-nav,
.ant-tabs-bottom>.ant-tabs-nav,
.ant-tabs-top>div>.ant-tabs-nav,
.ant-tabs-bottom>div>.ant-tabs-nav {
  margin: 0 0 0 0;
}

// TABCONTENT END

// ALL-WHITE-TABLE
.all-white-table {


  .ant-table-thead th.ant-table-column-has-sorters:hover {
    background: $blue-light !important;
  }

  tr.ant-table-row:nth-child(2n) {
    //background: white !important;
    background: transparent;
  }

  .ant-table {
    background: transparent;
    padding-bottom: 1rem;
  }

  .ant-table .ant-table-thead>tr>th {
    background: transparent;
    color: $blue-dark;
    font-weight: 600;
  }

  .ant-table-thead>tr>th {
    border-bottom: 3px solid $blue-dark;
  }

  .ant-table-tbody>tr>td {
    border-bottom: 1px solid $gray-middle;
    transition: background 0.3s;
  }
}