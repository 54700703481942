@import '../../styles/variables';

.ant-tag.ant-tag-geekblue {
  margin-bottom: 2pt;
}
.button-page {
  text-align: right;
}

#import-btn {
  margin-right: 10pt;
}

.filter-panel.ant-collapse {
  background: $filter-secondary;
  padding: 10px;
  color: $filter-main;
  margin-bottom: 20px;
  border: 2px solid $filter-main;
  border-radius: 3px;

  .filter {
    border-top: 1px solid $filter-main;
    border-bottom: 1px solid $filter-main;

    label {
      color: $filter-main !important;
    }
  
    .ant-select-selector {
      background: $filter-form-background !important;
  
      .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        background: $filter-main;
        color: $filter-secondary;
      }
    }
  
    .ant-picker {
      background: $filter-form-background !important;
    }
  
    .ant-input-affix-wrapper {
      background: $filter-form-background;
  
      .ant-input {
        background: $filter-form-background;
      }
    }
  
    .ant-input-number {
      background: $filter-form-background !important;
    }
  }

  .ant-collapse-item .ant-collapse-header {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    color: $filter-main;
    font-size: 20px;
    padding: 0 30px;

    &::after,
    &::before {
      display: none;
    }
  }
}

.filter-form-wrapper {
  border-top: 1px solid #fff;
  padding-top: 20px;
  .ant-form {
    flex-wrap: wrap;
    .ant-row.ant-form-item {
      label {
        color: #fff;
      }
    }

    .division-form-item {
      margin-top: -6px;
    }

    .ant-picker.ant-picker-range {
      width: 100%;
    }
  }

  .form-action-btns {
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding-bottom: 24px;

    .ant-btn:not(:first-child) {
      margin-left: 10px;
    }

    @media screen and (max-width: $md) {
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;

      .ant-btn {
        margin-top: 10px;
        width: 100%;
      }

      .ant-btn:not(:first-child) {
        margin-left: 0px;
      }
    }
  }
}

.action-btns {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .dropdown-btn {
    height: 2em;
  }

  .draft-button {
    margin: 8px 8px;
  }
}

.add-cluster-form {
  .contact-card {
    margin-bottom: 1rem;
  }

  .delete-contact-button-wrapper {
    display: flex;
    justify-content: right;
  }

  .add-contact-button-wrapper {
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
  }
}

.button-as-link {
  background: none !important;
  border: none;
  padding: 0 !important;
  color: #069;
  cursor: pointer;
  box-shadow: none;

  :hover {
    text-decoration: underline;
  }

  &.right {
    margin-left: auto;
  }
}

.no-person-container {
  display: flex;
  justify-content: right;
  margin-bottom: 1em;

  .office-link {
    color: $filter-main;
    text-decoration: underline;
  }
}

.ant-form-readonly {
  .ant-select-selector, .ant-input, .ant-input-number, .ant-input-affix-wrapper { 
    border: none!important;
    background-color: unset !important;

    .ant-input-prefix {
      color: black;
    }

    &,*{
      color: $font-color !important;
      cursor: default !important;
      &::placeholder {
        color: transparent!important;
      }  
    }
  }

  .ant-select-multiple {
    .ant-select-selector{
      font-size: 14px;
    }
    .ant-select-selection-item {
      border: 1px solid #f0f0f0!important;
      background-color: #f5f5f5!important;
    }
  } 

  .ant-select-selection-placeholder, .ant-select-arrow {
    display: none!important;
  }
}

.ant-table-scroll  {
  .ant-table-content {
    overflow: auto hidden!important;
  }
}

.centered-email-heading {
  margin-top: 1rem;
  .ant-input {
    text-align: center !important;
  }
}

.no-bullets {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

