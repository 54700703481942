// color variables
$blue-dark: #30597e;
$app-nav-bg-color: $blue-dark;
$app-nav-color: #fff;
$font-color: #1f1f1f;

$lg: 992px;
$md: 768px;
$sm: 579px;

// other variables
$name: imd-platform-commons;
$app-nav-width: 66px;
// usage
.#{$name}-apps {
  display: flex;
  flex: 1 0 auto;

  @media screen and (max-width: $md) {
    flex-direction: row-reverse;
  }
}

.#{$name}-apps-nav {
  // padding-top: 64px;
  flex-shrink: 0;
  width: $app-nav-width;
  background-color: $app-nav-bg-color;
  color: $app-nav-color;
  fill: $app-nav-bg-color;
  font-size: 9px;
  line-height: 12px;
  text-transform: uppercase;
  user-select: none;
  z-index: 900;
  /* -webkit-box-shadow: 4px 0px 8px 0px rgba(20, 20, 20, 0.5);
  -moz-box-shadow: 4px 0px 8px 0px rgba(20, 20, 20, 0.5);
  box-shadow: 4px 0px 8px 0px rgba(20, 20, 20, 0.5); */

  transition: all 0.3s ease-in;

  & center {
    margin: auto;
    height: $app-nav-width;
    padding: 10px 0px;
  }

  &.hidden {
    margin-left: calc(-1 * #{$app-nav-width});
    transition: all 0.3s ease-in;

    .toggle-apps-nav-wrapper {
      transition: all 0.3s ease-in;
      margin-right: -150px;
      border-bottom: none;

      .toggle-apps-nav-container {
        fill: $font-color;

        .caret-right {
          fill: $blue-dark;
        }
      }
    }
  }

  &.visible {
    margin-left: 0;
    transition: all 0.3s ease-in;
  }

  @media screen and (max-width: $md) {
    position: absolute;
    overflow-y: auto;
    height: 100%;
    margin-right: calc(-1 * #{$app-nav-width});
    transition: all 0.3s ease-in;
    &.visible {
      margin-right: 0;
    }
    &.hidden {
      display: none;
    }
  }
}

.#{$name}-apps-nav-item {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: $app-nav-width;
  width: $app-nav-width;
  cursor: pointer;
  position: relative;

  &:hover {
    &::after {
      content: ' ';
      // height: 20px;
      width: 3px;
      position: absolute;
      transform: translateY(calc(#{$app-nav-width}/ 2)) translateY(-10px);
      left: 0;
      top: 0;
      background: #fff;
      border-radius: 2px;
      animation: enlarge 0.3s ease-in-out forwards;
      transition: all 0.3s ease-in-out;
    }
  }
  & svg {
    height: $app-nav-width / 3;
    width: $app-nav-width !important;
    margin-bottom: 2px;
  }

  &.selected {
    transition: all 0.3s ease-in-out;

    &::after {
      content: ' ';
      height: 40px;
      width: 3px;
      position: absolute;
      transform: translateY(calc(#{$app-nav-width}/ 2)) translateY(-20px);
      left: 0;
      top: 0;
      background: #fff;
      border-radius: 2px;
      transition: all 0.3s ease-in-out;
    }

    &:hover {
      &::after {
        animation: enlarge-selected 0.3s ease-in-out forwards;
        height: $app-nav-width;
        transform: translateY(0);
      }
    }
  }
}

.#{$name}-apps-container {
  flex: 1;
}

.toggle-apps-nav-wrapper {
  margin-bottom: 10px;
  border-bottom: 2px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -5px;

  .toggle-apps-nav-btn {
    background: transparent;
    border: none;
    outline: none;

    &:hover {
      cursor: pointer;
    }

    .toggle-apps-nav-container {
      fill: #fff;
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      padding: 20px 0px;

      .bars {
        width: 20px;
      }

      .caret-left {
        height: 15px;
        margin-right: 5px;
      }

      .caret-right {
        height: 15px;
        margin-left: 5px;
      }
    }
  }
}

@keyframes enlarge {
  from {
    height: 0;
    transform: translateY(calc(#{$app-nav-width}/ 2));
  }
  to {
    height: 20px;
    transform: translateY(calc(#{$app-nav-width}/ 2)) translateY(-10px);
  }
}

@keyframes enlarge-selected {
  from {
    height: 40px;
    transform: translateY(calc(#{$app-nav-width}/ 2)) translateY(-20px);
  }
  to {
    height: $app-nav-width;
    transform: translateY(0);
  }
}