@import '../../styles/variables';

.ant-menu-submenu-selected {
  background: $filter-secondary !important;
}

.ant-layout-sider {
  background: $blue-light;

  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .logout-link {
    padding-left: 1em !important;
    margin-top: 1.5rem;
  }

  .menu {
    background: transparent;

    .ant-menu-inline .ant-menu-item:not(:last-child) {
      margin-bottom: 0;
    }

    a {
      display: inline;
    }

    .ant-menu.ant-menu-sub.ant-menu-inline::-webkit-scrollbar {
      display: none;
    }

    .ant-menu.ant-menu-sub.ant-menu-inline {
      max-height: 700px;
    }

    .ant-menu-submenu.plat-co-sub-menu {
      margin-top: -60px;
      margin-bottom: 10px;

      &:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon,
      &:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
        color: $blue-dark;
      }

      .ant-menu-submenu-title {
        width: 40px;
        float: right;
        top: -12px;
        // margin-top: -10px;
        &:active {
          background: transparent !important;
        }
      }

      .ant-menu-inline,
      .ant-menu-vertical,
      .ant-menu-vertical-left {
        background: rgba(#fff, 0.25);
        // margin-top: -5px;

        .ant-menu-item {
          background: transparent;
          height: 25px;
          line-height: 25px;
        }
      }
    }

    .ant-menu-item {
      // background: $blue-light;
      color: $blue-dark;
      font-weight: 600;

      &:hover {
        background: rgba(#fff, 0.9);
      }

      &.has-submenu {
        margin-bottom: 30px;
      }

      &.logout {
        color: $yellow;
      }

      .item-title {
        margin-left: 10px;
      }

      .item-title-submenu {
        margin-left: 10px;
        font-weight: 590;
      }

      &.ant-menu-item-selected {
        background: $filter-secondary;
        font-weight: 800;

        box-sizing: border-box;
        border-left: 0.5em solid $filter-main;

        &::after {
          display: none;
        }
      }
    }
  }

  .hide-mobile {
    display: none;
  }

  @media screen and (max-width: $headbar-full-maxWidth) {
    position: fixed;
    right: calc(-1 * #{$sider-width});
    height: 100vh;
    top: 0;
    transition: all 0.5s ease-out;

    .hide-mobile {
      display: block;
    }

    z-index: 10;

    &.visible {
      overflow-y: auto;
      right: 0px;

      $width: 100vw;

      flex: 0 0 $width !important;
      max-width: $width !important;
      min-width: $width !important;
      width: $width !important;
    }

    .user-area-mobile {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 70px;
      color: $blue-dark;

      svg,
      i {
        font-size: 50px;
        margin-bottom: 10px;
      }
    }

    .external-links {
      margin-top: 10px;

      ul {
        padding: 0;
        margin-left: -10px;
      }
    }
  }
}

.sider-footer {
  padding: 20px 16px 20px 24px;
  background: $blue-light;
  font-size: 12px;
  color: $blue-dark;

  span {
    margin-left: 5px;

    a {
      text-decoration: underline;
    }
  }
}

.thin {
  font-weight: 590;
}
.bold {
  font-weight: 600;
}

.sidebar-root {
  color: $blue-dark;
  &:hover {
    background: rgba(#fff, 0.9);
    color: $blue-dark !important;
  }
  .ant-menu-submenu-arrow {
    color: $blue-dark;
  }
}

.ant-menu-item.ant-menu-item-only-child.sidebar-submenu-item {
  margin: 6px;
}

.ant-menu.ant-menu-sub.ant-menu-inline {
  background: $blue-gray-light;
  .sidebar-submenu-item {
    &:hover {
      background: rgba(#fff, 0.9);
    }
  }
}

.sidebar-submenu-item {
  margin: 0;
}

.sidebar-submenu {
  font-weight: 590;
  @extend .sidebar-root;
}

.mobile {
  display: none;

  @media screen and (max-width: $headbar-full-maxWidth) {
    display: block;
  }
}
