@import '../../styles/variables';

.user-info-form-container {
    display: block;
    text-align: center;
    padding-top: 2rem;
    .user-info-form {
        width: 50%;
        @media screen and (max-width: $md) {
            width: 70%;
        }
        display: inline-block;
        margin-left: auto;
        margin-right: auto;
        text-align: left;
    }
}

.user-info-button-container {
    display: flex;
    justify-content: center;
    padding-bottom: 2rem;
    padding-top: 1rem;
    .user-info-button {
        width: 15em;
        display: flex;
        justify-content: space-around;
        align-content: center;
    }
}