$focus-indicator-color: $blue-dark;
$focus-indicator-color-alt: $gray-light;

.ant-btn:focus-visible {
    outline: 2px solid $focus-indicator-color;
    outline-offset: 1px;
}

.ant-modal-close:focus-visible {
    outline: 2px solid $focus-indicator-color;
    outline-offset: 1px;
}

.filter-panel:focus-visible {
    outline: 2px solid $focus-indicator-color;
    outline-offset: 1px;
}

.ant-collapse-header:focus-visible {
    outline: 2px solid $focus-indicator-color !important;
    outline-offset: 1px !important;
}

.anticon:focus-visible,
.anticon-right:focus-visible,
.ant-collapse-arrow:focus-visible {
    outline: 2px solid $focus-indicator-color;
    outline-offset: 1px;
}

.ant-upload:focus-visible {
    outline: 2px solid $focus-indicator-color;
    outline-offset: 1px;
}

.ant-tabs-tab-btn:focus-visible {
    outline: 2px solid $focus-indicator-color;
    outline-offset: 1px;
}

.ant-pagination-item:focus-visible,
.ant-pagination-item-link:focus-visible,
.ant-pagination-prev:focus-visible,
.ant-pagination-next:focus-visible {
    outline: 2px solid $focus-indicator-color;
    outline-offset: 1px;
}

.ant-breadcrumb-link:focus-visible {
    outline: 2px solid $focus-indicator-color;
    outline-offset: 1px;
}

.ant-menu.ant-menu-root:focus-visible {
    box-shadow: none !important;
    outline: 2px solid $focus-indicator-color !important;
    outline-offset: 1px !important;
}

.selectable-external-link:focus-visible {
    outline: 2px solid $focus-indicator-color;
    outline-offset: 1px;
  }

.headbar {
    .logo-wrapper:focus-visible {
        outline: 2px solid $focus-indicator-color;
        outline-offset: 1px;
    }
}


.selectable-sider-footer-item:focus-visible {
    outline: 2px solid $blue-dark;
    outline-offset: 1px;
}

.custom-ant-table-column-title:focus-visible, .active-sorting-icon:focus-visible, .inactive-sorting-icon:focus-visible {
    outline: 2px solid $focus-indicator-color-alt;
    outline-offset: 1px;
}

.toggle-apps-nav-btn.useAlt:focus-visible{
    outline: 2px solid $focus-indicator-color-alt !important;
    outline-offset: -2px !important;
}

.toggle-apps-nav-btn.doNotUseAlt:focus-visible {
    outline: 2px solid $focus-indicator-color !important;
    outline-offset: -2px !important;
}

.user-area:focus-visible {
    outline: 2px solid $focus-indicator-color-alt;
    outline-offset: -1em;
}

.cluster-anlegen-link:focus-visible {
    outline: 2px solid $focus-indicator-color;
    outline-offset: 2px;
}

.beitrag-tag-section-header {
    margin-right: 0 !important;
    display: inline-block;
}

.ant-select-selection-search-input:focus {
    outline: 2px solid $focus-indicator-color;
    outline-offset: 2px;
}

.ant-picker-calendar-mode-switch:focus-visible {
    outline: 2px solid $focus-indicator-color;
    outline-offset: 2px;
}

.event-link-calendar:focus-visible {
    outline: 2px solid $focus-indicator-color;
    outline-offset: 2px;
}

.logout-link:focus-visible {
    outline: 2px solid $focus-indicator-color;
    outline-offset: 2px;
    background-color: $gray-light;
}

.office-link:focus-visible {
    outline: 2px solid $focus-indicator-color;
    outline-offset: 2px;   
}