
$yellow: #cead00;
$orange: #dda85a;
$red: #e94249;
$green: #258a50;
$teal: #127b7d;
$blue-dark: #30597e;
$blue-medium: #a8b9c9;
$blue-light: #eaeef2;

$filter-placeholder-text: #656565;
$filter-main: #30597e;
$filter-secondary: #fff;
$filter-form-background: #e8ebee;

$font-color: #1f1f1f;

$gray-dark: #888888;
$gray-middle: #eeeeee;
$gray-light: #fafafa;

$blue-gray-light: #ecf0f3;
$blue-gray-dark: #d1d7dd;

$font-size: 16px;
$font-size-lg: 22px;

$font-size-lg-mobile: 16px;

$xxxl: 1780px;
$xxl: 1500px;
$xl: 1200px;
$lg: 992px;
$md: 768px;
$sm: 579px;

$headbar-full-maxWidth: 920px;

$header-height: 64px;

$app-nav-width: 66px;
$page-padding-mobile: 20px;
$page-padding: 50px;
$logo-padding: 30px;

$sider-width: 250px;

$content-max-width: 1400px;

@font-face {
    font-family: "Open Sans Regular";
    src: url("./open-sans/OpenSans-Regular.ttf");
}

@mixin openSans {
    font-family: Metropolis, sans-serif;
    font-weight: 400;
    font-style: normal;
  }

  
$font-family: 'Open Sans Regular';