@import '../../styles/variables';

.ant-layout-header {
  padding: 0;
}

.external-links ul {
  display: flex;
  list-style: none;
  margin-bottom: 0;
  padding: 0 20px;

  li {
    a {
      padding: 0 10px;
      font-weight: 600;
    }

    &:not(:last-child) a {
      border-right: 1px solid $blue-dark;
    }
  }
}

.display-block {
  display: block;

}

.display-none {
  display: none;
}

.headbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 12;
  position: sticky;
  

  .logo-wrapper {
    margin-left: $logo-padding;
    font-size: $font-size-lg;
    cursor: pointer;

    transition: all 0.3s ease-in;

    &.indent {
      transition: all 0.3s ease-in;
      // padding-left: $app-nav-width;
    }

    .logo {
      height: 50px;
    }

    @media screen and (max-width: $lg) {
      margin-left: 10px;
    }

  }

  .headbar-right {
    display: flex;

    .toggle-menu-btn {
      display: none;
    }

    .user-area {
      padding: 0 $page-padding;
      color: #fff !important;
      background: $blue-dark;
      height: 65px;

      span {
        margin-left: 10px;
        margin-right: 10px;
      }

      .user-area-icon {
        transform: translateY(-25%);
      }
    }
  }

  @media screen and (max-width: $headbar-full-maxWidth) {
    .logo-wrapper {
      margin-left: $page-padding-mobile;
      font-size: $font-size-lg-mobile;
      z-index: 12;

      &.indent {
        padding-left: 0;
      }

      .logo {
        height: 40px;
      }

      span {
        display: none;
      }
    }

    &.backgroundBlueLight {
      background-color: $blue-light;
    }

    .headbar-right {
      .external-links {
        display: none;
      }

      .user-area {
        display: none;
        padding: 0 $page-padding-mobile;
        margin-right: 60px;

        span {
          display: none;
        }
      }

      .toggle-menu-btn {
        background: $blue-dark;
        border: none;
        color: #fff;
        outline: none;
        border-radius: 0;
        padding: 0 20px;
        height: $header-height;
        width: 100px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: $font-size-lg;
      }
    }
  }
}

.toggle-menu-btn-close-hidden {
  display: none;
}

.toggle-menu-btn-close-visible {
  display: none;

  @media screen and (max-width: $headbar-full-maxWidth) {
      background: transparent;
      z-index: 12;
      border: none;
      color: #fff;
      outline: none;
      border-radius: 0;
      padding: 0 40px;
      height: $header-height;
      width: 50px;
      display: flex;
      align-items: center;
      font-size: 30px;
  }
}

.logout-link {
  color: $yellow !important;
  .logout-text {
    margin-left: 1em;
  }
}

.breadcrumbs {
  background: $filter-main;
  padding: 10px $page-padding 10px $page-padding;
  font-weight: 600;

  text-transform: capitalize;

  @media screen and (max-width: $lg) {
    padding: 10px $page-padding-mobile 10px $page-padding-mobile;
  }

  .ant-breadcrumb-separator {
    color: #fff;
  }

  a.ant-breadcrumb-link {
    color: #fff !important;
    &:hover {
      font-weight: 600;
      color: $filter-form-background !important;
    }
  }
}