@import '../../styles/variables';

.float-right {
  float: right;
}

.lazy-loading-image {
  width: 30em;
}

.home {
  .header {
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: center;
    align-items: center;

    @media screen and (min-width: $xl) {
      flex-direction: row;
      justify-content: space-between;
    }

    //action-btns with registration-btn (action btns in row and reg btn above the action btns (in column with action-btns))
    .reg-btn {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      & > :first-child {
        margin-bottom: 0.8rem;
        width: 18em;
        color: #fff !important;
        padding: 4px 15px !important;
        line-height: 1.5715 !important;

        @media screen and (max-width: $sm) {
          width: 18rem !important;
        }

        @media screen and (min-width: $sm) and (max-width: $xl) {
          align-self: center;
        }
      }

      .action-btns {
        .ant-btn:last-child {
          //margin-left: 0.8rem;
          width: 18em;
        }

        @media screen and (max-width: $sm) {
          display: flex;
          flex-direction: column;
          align-items: center;

          .ant-btn:not(:first-child) {
            margin-top: 10px;
            margin-left: 0px;
          }
          .ant-btn {
            width: 18rem !important;
          }
        }
      }
    }

    //action-btns without reg-btn
    .action-btns {
      .ant-btn:not(:first-child) {
        margin-left: 0.8rem;
      }

      @media screen and (max-width: $sm) {
        display: flex;
        flex-direction: column;
        align-items: center;
        .ant-btn:not(:first-child) {
          margin-top: 10px;
          margin-left: 0px;
        }
        .ant-btn {
          width: 20rem;
        }
      }
    }
  }

  .header-icon {
    margin-left: 10px;
  }

  .header-btn {
    .btn-icon {
      margin-left: 10px;
    }
  }

  .home-content {
    margin-bottom: 30pt;

    .current-notifications {
      margin-right: 40px;

      .pagination {
        padding: 15px 0;
      }

      .notification {
        background: $gray-light;
        padding: 20px;
        display: flex;
        align-items: center;
        border-radius: 6px;
        width: 50vw;

        .notification-icon {
          font-size: 40px;
          margin-right: 20px;
        }

        h2 {
          font-size: 20px;
        }

        .read-confirmation {
          text-align: right;
          margin-top: 10px;
        }

        &.info {
          border-left: 6px solid $blue-dark;
          h2,
          .notification-icon {
            color: $blue-dark !important;
          }
        }

        &.warning {
          border-left: 6px solid $orange;
          h2,
          .notification-icon {
            color: $orange !important;
          }
        }

        &.error {
          border-left: 6px solid $red;
          h2,
          .notification-icon {
            color: $red !important;
          }
        }
      }
    }

    @media screen and (max-width: $xl) {
      flex-direction: column;

      .current-notifications {
        margin-right: 0;

        .notification {
          width: auto;
        }
      }

      .last-used-apps {
        margin-top: $page-padding;
      }
    }
  }
  .logo {
    height: 200px;
    width: 200px;
  }
}

//NEWSENTRIES start
.newsletter-card {
  border: 1px solid $gray-middle;
  background: $gray-light;
  padding: 20px;
  border-radius: 4px;
  height: 20rem;
  margin-bottom: 4rem;
  display: flex;
  flex-direction: row;

  .content {
    flex: 90%;
    align-self: flex-start;
    height: 100%;

    .content-flex {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .newsletter-footer {
        display: flex;
      }

      .newsletter-footer > * {
        margin-right: 4rem;
      }
    }

    .tags {
      & > :first-child {
        margin-right: 0.8rem;
      }
    }
    .cluster {
      & > :first-child {
        margin-right: 0.8rem;
      }
    }
  }
}

//design of the card
.card {
  padding-bottom: 4rem;

  @media screen and (max-width: $xxl) {
    .antd-card {
      border-radius: 5px !important;

      .img-wrapper {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }
    }
  }

  @media screen and (min-width: $xxl) {
    .antd-card {
      border-radius: 5px !important;

      .img-wrapper {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
    }
  }

  .ant-card {
    background-color: $gray-light;
    border: none;

    &.elevate {
      -webkit-box-shadow: 0px 5px 13px -5px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 0px 5px 13px -5px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 5px 13px -5px rgba(0, 0, 0, 0.1);

      transition: all 0.2s;

      &:hover {
        -webkit-box-shadow: 0px 5px 13px -5px rgba(0, 0, 0, 0.4);
        -moz-box-shadow: 0px 5px 13px -5px rgba(0, 0, 0, 0.4);
        box-shadow: 0px 5px 13px -5px rgba(0, 0, 0, 0.4);
      }
    }

    .ant-card-body {
      padding: 0 !important;
    }
  }
  .card-content {
    .card-body {
      display: flex;
      flex-direction: column;
      flex: 80%;
      padding: 2rem;
      overflow: hidden;

      .entry-title {
        font-size: 20px;
        margin-bottom: 1rem;
        max-width: -moz-fit-content;
        max-width: fit-content;
        overflow-wrap: anywhere;
      }

      //card footer
      .card-footer {
        margin-top: auto;
        gap: 2rem !important;
      }

      .footer-pub-dates {
        .publishing-dates {
          display: flex;
          margin-left: auto;
          color: #545454;
          gap: 2rem;

          @media screen and (min-width: $xxl) {
            justify-content: flex-end;
            margin-top: 1rem;
          }

          .svg-inline--fa {
            margin-right: 0.8rem;
          }

          .published {
            margin-bottom: 1rem;
          }

          @media screen and (max-width: $sm) {
            & > * {
              & > * {
                display: flex;
                flex-direction: column;
                align-items: center;
              }
            }
          }
        }
      }

      @media screen and (max-width: $xxl) {
        .card-footer {
          display: flex;
          text-align: center;
          align-items: flex-start !important;
          margin-top: 2rem;

          & > :last-child {
            margin-right: 0rem !important;
          }

          & > *:not(.footer-pub-dates):not(.footer-reg-btn):not(.footer-more-info-btn) {
            flex: 10%;
            display: flex;
            flex-direction: column;
            align-items: stretch;
            align-self: flex-end;
            margin-top: 1rem;

            & :first-child {
              width: auto;

              &.icon {
                margin-bottom: 0rem !important;
              }
            }
          }
        }

        .footer-pub-dates {
          .publishing-dates {
            flex-direction: column;
            justify-content: center;
          }
        }
      }

      @media screen and (min-width: $xxl) {
        .card-footer {
          display: flex;
          margin-top: auto;
          align-items: baseline;

          & > * {
            & :first-child {
              margin-right: 0.8rem;

              @media screen and (min-width: $xxl) {
                &.footer-btn {
                  width: 100%;
                  text-align: end;
                }
              }
            }
          }
        }
      }
    }

    @media screen and (max-width: $xxl) {
      .footer-pub-dates {
        .publishing-dates {
          flex: 80%;
          margin-top: 2rem;
          flex-direction: row !important;
          justify-content: center;

          .published {
            margin-right: 2rem;
          }
        }
      }
    }

    //design for NEWSCARDS
    &.newscard {
      display: flex;
      //height: 20em;

      .text-ellipsis {
        width: calc(100vw - 100px);
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;

        a {
          text-decoration: underline;
        }
      }

      @media screen and (max-width: $sm) {
        height: auto !important;
      }

      @media screen and (max-width: ($xxl - 1px)) {
        flex-direction: column;
        //height: 42em;

        .img-wrapper {
          max-height: 20rem;
          top: 40px;
          height: auto;
          width: auto;
          overflow: hidden;
          flex: 50%;
          order: -1;
        }
        .ant-image {
          transform: translateY(130px) translateY(-50%);
          display: block;
        }
        .card-body {
          //flex: 50% !important;
          overflow: hidden;
          justify-content: flex-start !important;
        }

        .text-ellipsis {
          -webkit-line-clamp: 3;

          a {
            text-decoration: underline;
          }
        }
      }

      @media screen and (min-width: $xxl) {
        max-height: 22em;

        .ant-image {
          transform: translateY(-30px) translateX(-30px);
        }
        .img-wrapper {
          max-height: 23em;
          width: 30em;
          overflow: hidden;
          order: -1;
        }
        .ant-image-img {
          object-fit: cover;
          width: 35em;
          height: 25em;
        }
        .card-body {
          .body-text {
            max-width: 90em;

            a {
              text-decoration: underline;
            }
          }
        }
        .text-ellipsis {
          -webkit-line-clamp: 3;

          a {
            text-decoration: underline;
          }
        }
      }

      @media screen and (min-width: $xxxl) {
        .whole-footer {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }

    //design for single NEWSLETTER
    &.newsletter {
      .text-ellipsis {
        width: 100%;
        margin-bottom: 2rem;

        a {
          text-decoration: underline;
        }
      }

      .image-title {
        color: #545454;
      }

      .hero-btn-gallery {
        position: absolute;
        top: 2%;
        left: 2%;
        border-radius: 50% !important;
        &:hover {
          background: none;
        }

        .info-btn {
          font-size: large;
        }
      }

      .hero-btn {
        position: absolute;
        top: 2%;
        left: 2%;
        border-radius: 50% !important;

        &:hover {
          background: none;
        }
      }

      .info-btn {
        color: white;
        font-size: x-large;
        &:hover {
          color: darken(white, 5);
        }
      }

      .img-wrapper {
        text-align: center;
        position: relative;
      }

      @media screen and (max-width: $xxxl) {
        display: flex;
        height: auto;
        flex-direction: column;

        .img-wrapper {
          .ant-image {
            max-height: 30em;
            overflow: hidden;
          }
          order: -1;
        }
        .card-body {
          flex: 50% !important;
          overflow: hidden;
          justify-content: flex-start !important;
        }
      }

      @media screen and (min-width: $xxxl) {
        display: flex;
        height: auto;

        .img-wrapper {
          width: 30em;
          overflow: hidden;
          align-self: flex-start;
          top: 40px;
          max-height: 40em;
          order: -1;

          .ant-image {
            max-height: 38em;
            overflow: hidden;
          }
        }
      }
    }

    //design for EVENTCARDS
    &.eventcard {
      display: flex;
      //height: 21em;

      .body-dates {
        margin-top: auto;
        margin-bottom: auto;
        display: flex;
        column-gap: 2rem;
      }

      .text-ellipsis {
        width: calc(100vw - 100px);
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;

        a {
          text-decoration: underline;
        }
      }

      @media screen and (max-width: $sm) {
        height: auto !important;
        .body-dates {
          flex-wrap: wrap;
          flex-direction: column;
        }
      }

      @media screen and (min-width: $xxxl) {
        .whole-footer {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }

      @media screen and (min-width: $xxl) {
        max-height: 23em;

        .img-wrapper {
          max-height: 23em;
          width: 30em;
          overflow: hidden;
          order: -1;

          .ant-image {
            transform: translateY(-25px) translateX(-15px);
          }
          .ant-image-img {
            object-fit: cover;
            width: 32em;
            height: 25em;
          }
        }
        .body-text {
          max-width: 90em;
        }

        .body-dates {
          max-width: 90em;
          margin-top: auto;
          margin-bottom: 1rem;
        }
        .card-footer {
          margin: 0rem;
        }
        .text-ellipsis {
          -webkit-line-clamp: 3;
          a {
            text-decoration: underline;
          }
        }

        .region {
          .location-text {
            -webkit-line-clamp: 2;
            max-width: 100%;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }
      }

      @media screen and (max-width: ($xxl - 1px)) {
        flex-direction: column;

        .img-wrapper {
          max-height: 20rem;
          align-self: flex-start;
          top: 40px;
          height: auto;
          width: 100%;
          overflow: hidden;
          flex: 50%;
          order: -1;
        }
        .ant-image {
          transform: translateY(130px) translateY(-50%);
          display: block;
        }
        .card-body {
          flex: 50% !important;
          overflow: hidden;
          justify-content: flex-start !important;
        }
        .text-ellipsis {
          -webkit-line-clamp: 3;

          a {
            text-decoration: underline;
          }
        }
      }
    }

    //design for single event
    &.events {
      .text-ellipsis {
        width: 100%;
        margin-bottom: 2rem;
        a {
          text-decoration: underline;
        }
      }
      .collapse-header {
        margin-bottom: 3rem;
      }
      .hero-btn {
        position: absolute;
        top: 2%;
        left: 2%;
        border-radius: 50% !important;

        &:hover {
          background: none;
        }
      }
      .info-btn {
        color: white;
        font-size: x-large;
        &:hover {
          color: darken(white, 5);
        }
      }
      .img-wrapper {
        position: relative;
      }

      @media screen and (max-width: $xxxl) {
        display: flex;
        height: auto;
        flex-direction: column;

        .img-wrapper {
          text-align: center;
          order: -1;

          .image-title {
            color: #545454;
          }

          .ant-image {
            max-height: 30em;
            overflow: hidden;
            width: 100%;
          }
        }
        .card-body {
          flex: 50% !important;
          overflow: hidden;
          justify-content: flex-start !important;
        }
        .body-dates {
          margin-bottom: 2rem;
          display: flex;
          flex-direction: column;

          & > :last-child {
            margin-right: 0rem !important;
          }

          & > * {
            flex: 10%;
            display: flex;
            align-items: stretch;

            & :first-child {
              width: auto;
              margin-right: 0.8rem;
              margin-bottom: 1rem;
            }
          }
        }
      }

      @media screen and (min-width: $xxxl) {
        display: flex;
        height: auto;

        .img-wrapper {
          height: auto;
          width: 30em;
          overflow: hidden;
          text-align: center;
          align-self: flex-start;
          top: 40px;
          max-height: 35em;
          order: -1;

          .image-title {
            color: #545454;
          }

          .ant-image {
            max-height: 33em;
            overflow: hidden;
          }
        }
        .body-dates {
          display: flex;
          margin-bottom: 3rem;
          margin-top: auto;
          & > * {
            margin-right: 2rem;
          }
        }
      }
    }
  }
}

.card-text {
  width: 100%;
}

.collapse-header {
  margin-bottom: 2rem;

  .ant-collapse-header {
    padding: 0px !important;
    width: 13em;
  }

  .sourecs {
    display: flex;

    .number {
      margin-right: 1rem;
    }
  }
}

.section-title {
  font-size: medium;
  font-weight: 600;
  margin-bottom: 1rem;
}

.gallery {
  margin-bottom: 2rem;

  .image-container {
    text-align: center;
    position: relative;
  }

  @media screen and (min-width: $xl) {
    .image-flex-container {
      display: flex;
      position: relative;

      .image-container {
        margin: 0rem 1rem;

        .ant-image {
          height: auto;
          width: 100%;

          .ant-image-img {
            object-fit: cover;
            height: 10em;
          }
        }
      }
    }
  }

  @media screen and (max-width: $xl) {
    .image-flex-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      .image-container {
        margin-bottom: 1rem;

        .ant-image {
          max-height: 25em;
          overflow: hidden;
          max-width: 20em;
        }
      }
    }
  }
}
//NEWSENTRIES end

.previous-footer-btn {
  display: flex;
  flex-direction: row;

  & > .ant-btn {
    margin-left: auto;
  }
}
.ant-pagination {
  text-align: center;
}

//FILTER start
.filter-panel {
  padding: 10px;
  background: $blue-medium;
  color: #fff;
  margin-bottom: 20px;

  .ant-collapse-item .ant-collapse-header {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    font-size: 20px;
    padding: 0 30px;

    &::after,
    &::before {
      display: none;
    }
  }
}
.flex {
  display: flex;
  flex-direction: column;
  padding: 0 15px;

  .filter {
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    padding: 20px 0px;

    .ant-form-item {
      .ant-form-item-control {
        flex: 1 1 !important;
      }

      label {
        color: #fff;
        padding: 0px !important;

        .svg-inline--fa {
          margin-left: 0.5rem;
        }
      }
    }
    .ant-col-24.ant-form-item-label {
      padding: 0px !important;
    }
  }

  //FILTER BTNS start
  .filter-btns {
    display: flex;
    flex: 15%;
    justify-content: flex-end;
    padding-top: 1rem;

    & > :first-child {
      margin-right: 1rem;
    }

    .svg-inline--fa {
      margin-left: 0.5rem;
    }

    @media screen and (min-width: $sm) and (max-width: $md) {
      display: flex;
      flex: 15%;
      justify-content: center;
      margin-top: 1rem;

      & > * {
        flex: 50%;
      }
    }

    @media screen and (max-width: $sm) {
      justify-content: center;
      margin-top: 1rem;
      flex-direction: column;

      & > :first-child {
        margin-bottom: 1rem;
      }
    }
  }
  //FILTER BTNS end
}

//SEARCH-SORT start
.search-sorter {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 2rem;

  & > :first-child {
    margin-right: 1rem;

    & > :first-child {
      margin-right: 0.5rem;
    }
  }
  .search {
    button {
      background-color: $filter-main;
      & > :first-child {
        color: #fff;
      }
    }
  }

  .sorting {
    display: flex;
    button {
      & > :first-child {
        vertical-align: text-bottom;
        font-weight: 500;
      }

      svg {
        font-size: 22px;
        vertical-align: text-bottom;
      }
    }
  }

  @media screen and (max-width: $sm) {
    flex-direction: column;
    align-items: stretch;

    .search {
      width: 100%;
      align-items: center;
      margin-bottom: 1rem;
    }
    .sorting {
      justify-content: center;
    }
  }

  @media screen and (min-width: $sm) and (max-width: $xxl) {
    .search {
      flex: 80%;
      align-items: center;
    }

    .ant-btn-text {
      &:active {
        color: $gray-dark;
      }

      &[disabled] {
        color: $gray-dark;
      }
    }
  }
  @media screen and (min-width: $xxl) {
    .search {
      flex: 50%;
    }
    .sorting {
      flex: 50%;
    }
  }
}
//SEARCH-SORT end

.icon-left {
  margin-right: 0.5rem;
}

.filter {
  .ant-form-item {
    .ant-form-item-label {
      width: 180px;
      flex: 0 0 auto;
    }
  }

  .filter-date-row {
    justify-content: space-evenly;
    .ant-form-item {
      width: 50%;
    }

    @media screen and (max-width: $sm) {
      flex-direction: column;
      .ant-form-item {
        width: 100%;
      }
    }
  }
}

.input-100 {
  // we simulate 16 margin right
  width: calc(100% - 16px) !important;
}

.margin-right-16 {
  margin-right: 16px;
}
//FILTER end

//Calendar start
.calendar-view {
  .list-underneath {
    .event-link-calendar {
      text-decoration: underline !important;
    }
  }

  .ant-picker-calendar {
    .ant-picker-content {
      text-align: initial;
    }
  }
  .calendar-entries {
    li {
      color: $yellow;
    }
  }
  .calendar-component {
    thead {
      th {
        color: black;
      }
    }

    th {
      color: $filter-placeholder-text;
    }

    a {
      color: lighten($filter-main, 1);
      &:hover {
        color: darken($filter-main, 1);
      }
    }

    .ant-picker-cell {
      .ant-picker-calendar-date-value {
        color: $filter-placeholder-text;
      }
    }

    .ant-picker-cell.ant-picker-cell-in-view {
      .ant-picker-calendar-date-value {
        color: black;
      }
    }

    .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
    .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(
        .ant-picker-cell-range-start
      ):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(
        .ant-picker-cell-range-hover-end
      )
      .ant-picker-cell-inner {
      background-color: #f7f7f7;
    }

    .ant-picker-cell-selected {
      .ant-picker-cell-inner {
        background-color: $blue-light !important;
      }
    }

    @media screen and (max-width: $xxl) {
      .event-link-calendar {
        display: none;
      }
      .ant-picker-calendar-date-content {
        overflow-y: visible !important;
      }
      .calendar-entries {
        & > *:not(:first-child) {
          display: none;
        }
        @media screen and (max-width: $xxl) {
          padding-left: 1.5rem !important;
          display: flex;
          justify-content: center;
        }
      }
    }
  }
  .list-underneath {
    @media screen and (min-width: $xxl) {
      display: none;
    }
    margin-bottom: 4rem;
  }
  .no-entries {
    text-align: center;
  }
}

//used to disable the "year" button for the antd calendar
.calendar-component {
  .ant-picker-calendar-header {
    .ant-picker-calendar-mode-switch {
      .ant-radio-button-wrapper {
        display: none;
        &.ant-radio-button-wrapper-checked {
          display: block;
        }
      }
    }
  }
}

//Calendar end

.reg-btn-event {
  margin-left: auto;
  color: white !important;
  align-items: baseline;
  padding: 4px 15px !important;
  line-height: 1.5715 !important;

  &:hover {
    color: darken(white, 5) !important;
  }
}

.reg-btn-below {
  display: flex;
  padding-bottom: 4rem;

  & > :first-child {
    padding: 4px 15px !important;
    line-height: 1.5715 !important;
    color: white !important;
    width: 18em;
  }

  &:hover {
    color: darken(white, 5) !important;
  }

  @media screen and (max-width: $xl) {
    justify-content: center;
  }

  @media screen and (min-width: $xl) {
    justify-content: flex-end;
  }
}

.stack-navigator {
  display: flex;
  margin-top: 2rem;
  @media screen and (max-width: $sm) {
    flex-direction: column;
    justify-content: center;
    & > :not(:first-child) {
      margin-top: 1rem;
    }
    & > * {
      margin: auto;
    }
  }

  .future {
    margin-right: auto;
  }
  .past {
    margin-left: auto;
  }
}

.left15 {
  margin-left: 15px !important;
}

.secondary-button {
  border: 2px solid $filter-main;
  color: $filter-main;
  font-weight: bolder;

  border-radius: 3px;
  box-shadow: 2px 0 rgba(0, 0, 0, 0.045);
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
}

.filter-panel-btn {
  padding: 0px 25px 15px 25px !important;
  border: 2px solid #30597e;
  border-radius: 3px;
  color: #fff;
  margin-bottom: 20px;

  .filter {
    label {
      color: $filter-main !important;
    }

    .ant-select-selector {
      background: $filter-form-background !important;

      .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        background: $filter-main;
        color: $filter-secondary;
      }

      .ant-select-selection-item {
        color: $filter-secondary;
        background: $filter-main;
        border: $filter-secondary;

        .anticon-close {
          color: $filter-secondary;
        }
      }

      .ant-select-selection-placeholder {
        color: $filter-placeholder-text;
      }
    }

    .ant-picker {
      background: $filter-form-background !important;
    }

    .ant-input-number {
      background: $filter-form-background !important;
    }
  }

  .ant-collapse-item .ant-collapse-header {
    display: flex;
    border: 1px solid #30597e;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    font-size: 20px;
    padding: 0 30px;

    &::after,
    &::before {
      display: none;
    }
  }
}

.ant-table-column-sorter-up.active {
  color: $yellow;
}

.ant-table-column-sorter-down.active {
  color: $yellow;
}

.footer-more-info-btn {
  align-self: flex-end;

  @media screen and (max-width: $xxl) {
    flex: 10% 1;
  }
}

.footer-more-info-btn {
  background-color: #eaeaea;
  &:hover {
    background-color: #dddddd;
  }
}

.footer-reg-btn {
  align-self: flex-end;

  @media screen and (max-width: $xxl) {
    flex: 10% 1;
  }
}

.region {
  display: flex;
  margin-bottom: 0px !important;

  .location-text {
    padding-left: 0.3em;
    white-space: pre-wrap;
  }
}
