@import '../../styles/variables';

// Unternehmen anlegen Modal

.modal-header {
  /* display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  border-bottom: 1px solid rgba(112, 112, 112, 0.25);
  margin-bottom: 2rem; */

  h1 {
    font-weight: 600;
  }

  .header-icon {
    transform: rotate(45deg);
    font-size: $font-size-lg;
  }
}


.modal-title {
  color: $blue-dark;
}

.modal-title-user {
  margin-left: 6px;
  font-size: 0.75em;
}

.modal-content {
  background-color: rgba(236, 240, 243, 0.75);
  padding: 20px;
  border-radius: 6px;

  .content-title {
    border-bottom: 1px solid rgba(112, 112, 112, 0.25);
    margin-bottom: 1rem;

    h2 {
      font-weight: 600;
    }
  }
}

.btn-icon {
  margin-left: 10px;
}

.rotate {
  transform: rotate(45deg);
}

.cluster-dropdown-btn {
  margin-left: 8px !important;
}

.ant-modal-content {
  padding-top: 10px;
  padding-bottom: 10px;
}

.ant-modal-header {
  .ant-modal-title {
    font-size: $font-size-lg;
  }
}

.ant-modal-footer {
  border-top: none;
  padding: 10px 24px;

  .ant-btn {
    height: 40px;
    font-size: $font-size;
  }
}

.ant-col.ant-col-10.ant-form-item-label.ant-form-item-label-left {
  white-space: pre-wrap;
}

.ant-input-number.ant-input-number-lg {
  width: 100%;
}

#import-excel-file {
  text-align: center;
}

.link-icon {
  margin-right: 0.5rem;
}

.ant-modal {
  &.app-modal {
    @media screen and (min-width: $xl) {
      width: 55% !important;
    }

    @media screen and (min-width: $lg) and (max-width: $xxl) {
      width: 70% !important;
    }

    @media screen and (max-width: $lg) {
      width: 100% !important;
    }

    .ant-modal-footer {
      .footer-btns {
        display: flex;
        justify-content: space-between;

        &>* {
          display: flex;

          @media screen and (max-width: $xl) {
            flex-direction: column;

            &>* {
              margin-left: 0px;
            }

            &> :first-child {
              margin-bottom: 1rem;
            }
          }
        }

        @media screen and (max-width: $sm) {
          flex-direction: column;

          &> :first-child {
            margin-bottom: 2rem;
          }
        }
      }
    }

    .modal-header-with-button {
      display: flex;
      justify-content: flex-start;
      width: 95%;

      .header-button{
        margin-left: auto;
      }
    }
  }
}

.headbar-modal {
  .modal-content {
    a {
      text-decoration: underline;
    }
    
    .content-form {

      img {
        max-width: 100%;
        height: auto;
        object-fit: cover;
      }
    }
  }
}